import { IActionContext, ICoreContext, getCatalogId } from '@msdyn365-commerce/core';
import { ICartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import { SimpleProduct, Cart } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { addOverridesToCartState } from './cart-attribute-helpers';

//==============================================================================
// INTERFACES AND TYPES
//==============================================================================

export interface CartAttributeValues {
    '@odata.type': "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue",
    Name: string,
    TextValue: string,
    TextValueTranslations: [],
    ExtensionProperties: []
}

export type AddByIdItems = {
    Id: string,
    Quantity: number,
    AttributeValues?: {},     // format as { attributeName: attributeValue }
    isEmailDelivery?: true
}[];

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * Util additions for cart
 */
//==============================================================================
export class CartUtilities {

    protected actionContext: IActionContext;
    protected cart: ICartState;

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Constructor
    //------------------------------------------------------
    constructor(actionContext: IActionContext, cart: ICartState) {
        this.actionContext = actionContext;
        this.cart = cart;
    }

    //------------------------------------------------------
    // Allows adding to cart by item Id and able to pass through custom attributes that will be set as cartline attributes
    //------------------------------------------------------
    public static async addToCartByIds(cartState: ICartState, context: ICoreContext, items: AddByIdItems): Promise<ICartActionResult> {
        if (!cartState || !cartState.cart) {
            context.telemetry.error('[CartUtilities.addToCartByIds] Failed to load cart');
            return {status: 'FAILED'};
        }

        if (!items?.length) {
            context.telemetry.error('[CartUtilities.addToCartByIds] Failed to send, no item ids passed');
            return {status: 'FAILED'};
        }

        // overrides function to allow custom attributes
        addOverridesToCartState(cartState);

        const itemIds = items.map(item => {
            return { ItemId: item.Id};
        });

        const searchCriteria = {
            ItemIds: itemIds,
            Context: {
                ChannelId: context.request.apiSettings.channelId,
                CatalogId: getCatalogId(context.request)
            }
        };

        const products = await searchByCriteriaAsync({ callerContext: context.actionContext }, searchCriteria);

        if (!products?.length) {
            context.telemetry.error('[CartUtilities.addToCartByIds] Failed to find products based on itemIds');
            return {status: 'FAILED'};
        }

        const addToCartInput = products.map(product => {
            const item = items.find(item => {
                return item.Id === product.ItemId;
            });

            const simpleProduct = {
                RecordId: product.RecordId
            } as SimpleProduct;

            return {
                product: simpleProduct,
                count: item?.Quantity || 1,
                catalogId: getCatalogId(context.request),
                customAttributes: item?.AttributeValues,
                isAddEmailDeliveryItemToCart: item?.isEmailDelivery
            };
        });

        return cartState.addProductsToCart(addToCartInput);

    }

    //------------------------------------------------------
    // Add to cart attributes
    //------------------------------------------------------
    public static addToCartAttributes = (cart: Cart, attributeName: string, attributeValue: string): void => {
        if (!cart.AttributeValues) {
            cart.AttributeValues = [];
        } else {
            const attributeToUpdate = cart.AttributeValues.find(attribute => attribute.Name === attributeName);
            if (attributeToUpdate) {
                attributeToUpdate['TextValue'] = attributeValue;
            } else {
                cart.AttributeValues.push(
                    {
                        // @ts-expect-error -- Need to provide data type.
                        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
                        Name: attributeName,
                        TextValue: attributeValue,
                        ExtensionProperties: [],
                        TextValueTranslations: []
                    }
                );
            }
        }
    };
}