//==============================================================================
// Tools to help read, write, and process cart header and line attributes
//
// There is currently no OOTB way to add attributes to cart lines when adding
// products to the cart. We would have to add to the cart, then modify the
// newly created line, using two separate retail server calls. Alternately,
// low-level retail server calls could be used, followed by a cart refresh.
// This optimizes the process by allowing a single call, even though the method
// is not ideal.
//==============================================================================

import { getCatalogId } from '@msdyn365-commerce/core';
import { CartLine, OrgUnitLocation, ReleasedProductType, SimpleProduct } from "@msdyn365-commerce/retail-proxy";
import { ICartActionResult, ICartState, BaseCartState, addProductToCartInternal, addProductsToCartInternal } from '@msdyn365-commerce/global-state';

//==============================================================================
// INTERFACES
//==============================================================================

export interface AddProductsToCartWithAttribs {
    product: SimpleProduct;
    count?: number;
    location?: OrgUnitLocation;
    additionalProperties?: object;
    availableQuantity?: number;
    enableStockCheck?: boolean;
    isAddEmailDeliveryItemToCart?: boolean;
    isPriceKeyedIn?: boolean;
    customPrice?: number;
    shouldSkipSiteSettings?: boolean;
    catalogId?: number;
    deliveryMode?: string;
    customAttributes?: {};
}

export interface SalesOrderAttribute {
    '@odata.type': "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue",
    Name: string,
    TextValue: string,
    TextValueTranslations: [],
    ExtensionProperties: []
}

//==============================================================================
// Public Functions
//==============================================================================

//==========================================================
// This replaces base functionality with our extended version
//
// Old school extensions like this are rightly frowned upon.
// Any other method would be preferred, but there doesn't
// appear to be a reasonable option at this time.
//==========================================================
export function addOverridesToCartState(cartState: ICartState) {
    const extendedState = cartState as CustomBaseCartState;

    if (!extendedState.wasInjected) {
        extendedState.wasInjected = true;

        extendedState.addProductsToCart = CustomBaseCartState.prototype.addProductsToCartWithAttribs;
        extendedState.addProductToCart = CustomBaseCartState.prototype.addProductToCartWithAttribs;
        extendedState.baseRemoveCartLines = extendedState.removeCartLines;
    }
}

//==========================================================
// format custom attributes into attribute value structure
//==========================================================
function formatCustomAttributes(attributes: {}): SalesOrderAttribute[] {
    const formattedAttribs: SalesOrderAttribute[] = [];

    for (var key in attributes) {
        if (attributes[key]) {
            formattedAttribs.push({
                "@odata.type": "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue",
                "Name": key,
                "TextValue": attributes[key],
                "TextValueTranslations": [],
                "ExtensionProperties": []
            });
        }
    }

    return formattedAttribs;
}

//==============================================================================
// BaseCartState Overrides
//==============================================================================

//==========================================================
// This is a direct replacement for CartState.addProduct(s)ToCart
// that accepts line-level attributes
//
// TODO: When upgrading to newer versions of the SSK, be sure
// to update this function!
//==========================================================
class CustomBaseCartState extends BaseCartState {
    public wasInjected = false;

    public baseRemoveCartLines = this.removeCartLines;

    //----------------------------------------------------------
    //----------------------------------------------------------
    public async addProductsToCartWithAttribs(input: {
        product: SimpleProduct; count?: number; location?: OrgUnitLocation; additionalProperties?: object; availableQuantity?: number;
        enableStockCheck?: boolean; isAddEmailDeliveryItemToCart?: boolean; isPriceKeyedIn?: boolean; customPrice?: number; deliveryMode?: string; catalogId?: number; customAttributes?: {};
    }[]): Promise<ICartActionResult> {

        return this._doAsyncAction<ICartActionResult>(async () => {
            const internalInput: {
                cartLineToAdd: CartLine;
                availableQuantity?: number;
                isStockCheckEnabled?: boolean;
                isAddServiceItemToCart?: boolean;
            }[] = [];

            for (const inputItem of input) {
                const attributeValues = inputItem.customAttributes && formatCustomAttributes(inputItem.customAttributes);
                const cartLine: CartLine = {
                    CatalogId: inputItem.catalogId ?? getCatalogId(this.actionContext.requestContext),
                    Description: inputItem.product.Description,

                    // TODO: Investigate this value and what it represents
                    EntryMethodTypeValue: 3,
                    ItemId: inputItem.product.ItemId,
                    ProductId: inputItem.product.RecordId,
                    Quantity: inputItem.count || 1,
                    TrackingId: '',
                    UnitOfMeasureSymbol: inputItem.product.DefaultUnitOfMeasure,
                    IsPriceKeyedIn: inputItem.isPriceKeyedIn,
                    IsGiftCardLine: inputItem.product.IsGiftCard,
                    Price: inputItem.customPrice ? inputItem.customPrice : inputItem.product.Price,
                    AttributeValues: attributeValues
                };

                if (inputItem.location) {
                    if (!this.actionContext.requestContext.channel) {
                        return { status: 'FAILED' };
                    }

                    // If curbside pick is not available use the default one
                    if (inputItem.deliveryMode !== undefined) {
                        cartLine.DeliveryMode = inputItem.deliveryMode;
                    } else {
                        cartLine.DeliveryMode = this.actionContext.requestContext.channel.PickupDeliveryModeCode;
                    }

                    cartLine.FulfillmentStoreId = inputItem.location.OrgUnitNumber;
                    cartLine.WarehouseId = inputItem.location.InventoryLocationId;
                    // @ts-expect-error
                    cartLine.ShippingAddress = this._buildAddressFromOrgUnitLocation(inputItem.location);
                }

                if (inputItem.isAddEmailDeliveryItemToCart) {
                    cartLine.DeliveryMode = this.actionContext.requestContext.channel?.EmailDeliveryModeCode;
                }

                // Check if the product is service or not by product type
                const PRODUCTASSERVICE = 2 as ReleasedProductType.Service;
                const isAddServiceItemToCart = inputItem.product.ItemTypeValue === PRODUCTASSERVICE;

                internalInput.push({
                    cartLineToAdd: cartLine,
                    availableQuantity: inputItem.availableQuantity,
                    isStockCheckEnabled: inputItem.enableStockCheck,
                    isAddServiceItemToCart
                });
            }

            // @ts-expect-error
            return this._doCartOperationWithRetry(() => addProductsToCartInternal(
                this.cart,
                this.actionContext,
                internalInput
            ));
        });
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    public async addProductToCartWithAttribs(input: {
        product: SimpleProduct; count?: number; location?: OrgUnitLocation; additionalProperties?: object; availableQuantity?: number;
        enableStockCheck?: boolean; isAddEmailDeliveryItemToCart?: boolean; isPriceKeyedIn?: boolean; customPrice?: number; deliveryMode?: string;
        shouldSkipSiteSettings?: boolean; catalogId?: number; customAttributes?: {};
    }): Promise<ICartActionResult> {

        return this._doAsyncAction<ICartActionResult>(async () => {
            const attributeValues = input.customAttributes && formatCustomAttributes(input.customAttributes);
            const cartLine: CartLine = {
                CatalogId: input.catalogId ?? getCatalogId(this.actionContext.requestContext),
                Description: input.product.Description,

                // TODO: Investigate this value and what it represents
                EntryMethodTypeValue: 3,
                ItemId: input.product.ItemId,
                ProductId: input.product.RecordId,
                Quantity: input.count || 1,
                TrackingId: '',
                UnitOfMeasureSymbol: input.product.DefaultUnitOfMeasure,
                IsPriceKeyedIn: input.isPriceKeyedIn,
                IsGiftCardLine: input.product.IsGiftCard,
                Price: input.customPrice ? input.customPrice : input.product.Price,
                AttributeValues: attributeValues
            };

            if (input.location) {
                if (!this.actionContext.requestContext.channel) {
                    return { status: 'FAILED' };
                }

                // If curbside pick is not available use the default one
                if (input.deliveryMode !== undefined) {
                    cartLine.DeliveryMode = input.deliveryMode;
                } else {
                    cartLine.DeliveryMode = this.actionContext.requestContext.channel.PickupDeliveryModeCode;
                }

                cartLine.FulfillmentStoreId = input.location.OrgUnitNumber;
                cartLine.WarehouseId = input.location.InventoryLocationId;
                // @ts-expect-error
                cartLine.ShippingAddress = this._buildAddressFromOrgUnitLocation(input.location);
            }

            if (input.isAddEmailDeliveryItemToCart) {
                if (!this.actionContext.requestContext.channel) {
                    return { status: 'FAILED' };
                }

                cartLine.DeliveryMode = this.actionContext.requestContext.channel.EmailDeliveryModeCode;
            }

            // Check if the product is service or not by product type
            const isAddServiceItemToCart = input.product.ItemTypeValue === ReleasedProductType.Service;

            // @ts-expect-error
            const orderQuantityLimitsFeatureIsEnabled: boolean = !!input.additionalProperties?.orderQuantityLimitsFeatureIsEnabled;
            const isMaxByQuantityValidationFeatureDefined: boolean = !!input.product.Behavior?.MaximumQuantity;

            // @ts-expect-error
            return this._doCartOperationWithRetry(() => addProductToCartInternal(
                this.cart,
                cartLine,
                this.actionContext,
                orderQuantityLimitsFeatureIsEnabled,
                input.availableQuantity,
                input.enableStockCheck,
                isAddServiceItemToCart,
                isMaxByQuantityValidationFeatureDefined,
                input.shouldSkipSiteSettings
            ));
        });
    }
}
