//==============================================================================
// Performs an arbitrary list of actions in parallel, but limits the number
// running concurrently.
//==============================================================================

//==========================================================
// Performs a list actions, throttling to a maximum
// number of concurrent processes.
//
// Type <T> is the result of a single action
// IN: Array of functions that return a promise
// OUT: A promise that resolves when all actions resolve.
//
// Rejects and stops initiating actions if any action rejects.
//==========================================================
export function promiseAllN<T>(collection: (() => Promise<T>)[], maxConcurrency: number = 100): Promise<T[]> {
    let index = 0;
    let jobsLeft = collection.length;
    let rejected = false;
    const outcome: any = [];

    const pendingPromise: Promise<T[]> = new Promise(function (resolve, reject) {

        // Guard clause
        if (collection.length === 0) {
            return resolve([]);
        }

        // Execute the j'th thunk
        function runJob(j: number) {
            if (!rejected) {
                collection[j]().then((result: any) => {

                    // If an operation failed, early out. Don't start any more jobs.
                    if (rejected) { return; }

                    outcome[j] = result;

                    // If the last job just completed, resolve. We're done!
                    if (--jobsLeft <= 0) {
                        resolve(outcome);
                    }
                    // We're not done. Are there are jobs that haven't started yet? If so, start one.
                    else if (index < collection.length) {
                        runJob(index++);
                    }
                }).catch((e: any) => {
                    if (rejected) { return; }

                    rejected = true;        // Let any currently running jobs know to ignore their results
                    reject(e);
                });
            }
        }

        // Bootstrap, while handling cases where the length of the given array is smaller than maxConcurrent jobs
        while (index < Math.min(collection.length, maxConcurrency)) {

            // Added a check to see if a job insta-rejected
            if (!rejected) {
                runJob(index++);
            }
        }
    });

    return pendingPromise;
}
