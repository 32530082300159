//==============================================================================
// Look Inside
//
// Opens a modal with a select set of book pages that the customer can look
// through before purchasing.
//==============================================================================
import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { clsHelper } from '../../../../utilities/class-name-helper';
import { promiseAllN } from '../../../../utilities/parallel-promises';

import { sendRequest } from '@msdyn365-commerce/core';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';

import { IMediaGalleryResources } from '../../definition-extensions/media-gallery.ext.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
export interface ILookInsideProps {
    resources: IMediaGalleryResources;
    imageUrl: string;
    productId: string;
    productName?: string;
    productPrice?: string;
    maxCount?: number;
}

interface PageConfig {
    id: string;
    lookupString: string;
    title: string;
    maxPages: number;
}

interface PageConfigIndexed extends PageConfig {
    index: number;
}

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'look-inside';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * LookInside component
 * @extends {React.Component<ILookInsideProps>}
 */
//==============================================================================
@observer
class LookInside extends React.Component<ILookInsideProps> {
    //==========================================================================
    // VARIABLES
    //==========================================================================
    public lookInsideRef: React.RefObject<any>;
    @observable private isOpen: boolean = false;
    @observable private pageIndex: number = 0;
    private readonly resources: IMediaGalleryResources = this.props.resources;
    private readonly lookupStringPrefix: string = '_000';
    private readonly lookupStringExt: string = '.jpg';
    private readonly pages: PageConfig[] = [
        { id: 'frontCover',         lookupString: '_f',     title: this.resources.lookInside_titleFrontCover,           maxPages: 1 },
        { id: 'frontFlap',          lookupString: '_ff',    title: this.resources.lookInside_titleFrontFlap,            maxPages: 1 },
        { id: 'titlePage',          lookupString: '_t',     title: this.resources.lookInside_titleTitlePage,            maxPages: 1 },
        { id: 'copyright',          lookupString: '_cp',    title: this.resources.lookInside_titleCopyright,            maxPages: 2 },
        { id: 'tableOfContents',    lookupString: '_c',     title: this.resources.lookInside_titleTableOfContents,      maxPages: 15 },
        { id: 'preface',            lookupString: '_pp',    title: this.resources.lookInside_titlePreface,              maxPages: 10 },
        { id: 'dedication',         lookupString: '_d',     title: this.resources.lookInside_titleDedication,           maxPages: 1 },
        { id: 'introduction',       lookupString: '_i',     title: this.resources.lookInside_titleIntroduction,         maxPages: 25 },
        { id: 'lessonPlanOverview', lookupString: '_o',     title: this.resources.lookInside_titleLessonPlanOverview,   maxPages: 60 },
        { id: 'sampleChapter',      lookupString: '_p',     title: this.resources.lookInside_titleSampleChapter,        maxPages: this.props.maxCount || 5 },
        { id: 'sample',             lookupString: '_s',     title: this.resources.lookInside_titleSample,               maxPages: this.props.maxCount || 5 },
        { id: 'acknowledgements',   lookupString: '_a',     title: this.resources.lookInside_titleAcknowledgements,     maxPages: 3 },
        { id: 'backFlap',           lookupString: '_bf',    title: this.resources.lookInside_titleBackFlap,             maxPages: 1 },
        { id: 'backCover',          lookupString: '_b',     title: this.resources.lookInside_titleBackCover,            maxPages: 1 },
        { id: 'photoCredits',       lookupString: '_pc',    title: this.resources.lookInside_titlePhotoCredits,         maxPages: 10 }
    ];
    @observable private imageRefs: string[] = [];
    private readonly defaultPageStart: number = 1;
    private readonly defaultPageLookup: string = `${this.pages[0].lookupString}${String(this.defaultPageStart)}`;

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Constructor
    //------------------------------------------------------
    constructor(props: ILookInsideProps) {
        super(props);
        this.lookInsideRef = React.createRef();
    }

    //------------------------------------------------------
    // Invoked immediately after component is mounted
    //------------------------------------------------------
    public async componentDidMount(): Promise<void> {
        const { imageUrl, productId } = this.props;
        const enableLookInside = await this._checkMainImage(imageUrl, productId, this.defaultPageLookup);
        if (enableLookInside) {
            this._getImages(imageUrl, productId);
        }
    }

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        const { resources, productName, productPrice } = this.props;
        const pageConfig = this._createConfig();
        const defaultPageIndex = this._findPage(this.defaultPageLookup);
        const enableLookInside = !!this.imageRefs.length;

        // Do not return component if default page image does not exist
        if (!enableLookInside) {
            return null;
        }

        return (
            <div className={BASE_CLASS}>

                {/* Modal button */}
                <button className={cls('button')} ref={this.lookInsideRef} onClick={this._toggle} aria-label={resources.lookInside_button}>
                    {resources.lookInside_button}
                </button>

                {/* Modal window */}
                <Modal
                    autoFocus={true}
                    fade={false}
                    returnFocusRef={this.lookInsideRef}
                    isOpen={this.isOpen}
                    toggle={this._toggle}
                    className={cls('modal')}
                    horizontalPosition={'center'}
                    verticalPosition={'center'}
                >
                    <ModalHeader toggle={this._toggle} />
                    <ModalBody>
                        <div className={cls('container')}>

                            {/* Sidebar column*/}
                            <div className={cls('sidebar')}>

                                {/* Cover image */}
                                <div className={cls('cover')}>
                                    <img className={cls('cover-image')} src={this.imageRefs[defaultPageIndex]} alt={productName} />
                                </div>

                                {/* Product info */}
                                <div className={cls('product')}>
                                    <div className={cls('product-name')}>
                                        {productName}
                                    </div>
                                    <div className={cls('product-price')}>
                                        {productPrice}
                                    </div>
                                </div>

                                {/* Table of contents */}
                                <div className={cls('toc')}>
                                    <div className={cls('toc-heading')}>
                                        {resources.lookInside_headingTableOfContents}
                                    </div>
                                    <div className={cls('toc-list')}>
                                        {pageConfig.map(page => (
                                            <a
                                                key={page.id}
                                                id={page.id}
                                                className={cls('toc-link')}
                                                role='button'
                                                onClick={this._setPageIndex(page.index)}
                                            >
                                                {page.title}
                                            </a>
                                        ))}
                                    </div>
                                </div>

                            </div>

                            {/* Content column */}
                            <div className={cls('content')}>

                                {/* Page nav */}
                                <div className={cls('nav')}>
                                    <a
                                        className={classnames(cls('nav-link'), 'prev')}
                                        role='button'
                                        aria-label={resources.lookInside_pagePrevious}
                                        onClick={this._setPageIndex(this.pageIndex - 1 < 0 ? this.imageRefs.length - 1 : this.pageIndex - 1)}
                                    >
                                        {resources.lookInside_pagePrevious}
                                    </a>
                                    <a
                                        className={classnames(cls('nav-link'), 'next')}
                                        role='button'
                                        aria-label={resources.lookInside_pageNext}
                                        onClick={this._setPageIndex(this.imageRefs.length - 1 === this.pageIndex ? 0 : this.pageIndex + 1)}
                                    >
                                        {resources.lookInside_pageNext}
                                    </a>
                                </div>

                                {/* Page image */}
                                <div className={cls('page')}>
                                    <a
                                        className={cls('page-link')}
                                        role='button'
                                        aria-label={resources.lookInside_pageNext}
                                        onClick={this._setPageIndex(this.imageRefs.length - 1 === this.pageIndex ? 0 : this.pageIndex + 1)}
                                    >
                                        <img className={cls('page-image')} src={this.imageRefs[`${this.pageIndex}`]} alt={productName} />
                                    </a>
                                </div>

                                {/* Copyright text */}
                                <div className={cls('copyright')}>
                                    {resources.lookInside_copyright}
                                </div>

                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter />
                </Modal>
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    // Toggle modal
    //------------------------------------------------------
    private readonly _toggle = (): void => {
        this.isOpen = !this.isOpen;
    };

    //------------------------------------------------------
    // Set page index
    //------------------------------------------------------
    private readonly _setPageIndex = (index: number) => (): void => {
        this.pageIndex = index;
    };

    //------------------------------------------------------
    // Format lookup string
    //------------------------------------------------------
    private readonly _formatLookupString = (lookupString: string): string => {
        return `${this.lookupStringPrefix}${lookupString}${this.lookupStringExt}`;
    };

    //------------------------------------------------------
    // Finds page index with provided lookup string
    //------------------------------------------------------
    private readonly _findPage = (lookupString: string): number => {
        return this.imageRefs.findIndex((imageURL: string) => imageURL.includes(lookupString));
    };

    //------------------------------------------------------
    // Creates filtered page config based on images present
    //------------------------------------------------------
    private readonly _createConfig = (): PageConfigIndexed[] => {
        return this.pages.map(page => ({
            ...page,
            index: this._findPage(this._formatLookupString(`${page.lookupString}${String(this.defaultPageStart)}`))
        })).filter(page => page.index !== -1);
    };

    //------------------------------------------------------
    // Build image URL
    //------------------------------------------------------
    private readonly _getImageUrl = (baseImageUrl: string, productId: string, lookupString: string): string => {
        return `${baseImageUrl}Products%2F${productId}${this.lookupStringPrefix}${lookupString}${this.lookupStringExt}`
    };

    //------------------------------------------------------
    // Checks main image before getting the rest of the
    // images
    //------------------------------------------------------
    private readonly _checkMainImage = async (baseImageUrl: string, productId: string, lookupString: string): Promise<boolean> => {
        const imageUrl = this._getImageUrl(baseImageUrl, productId, lookupString);
        const imageResponse = await sendRequest(imageUrl, 'get');
        if (imageResponse?.status === 200) {
            return true;
        }
        return false;
    };

    //------------------------------------------------------
    // Builds array of images
    //------------------------------------------------------
    private readonly _getImages = async (baseImageUrl: string, productId: string): Promise<void> => {
        const imageUrls: string[] = [];
        this.pages.forEach(page => {
            for (let i = 1; i <= page.maxPages; i++) {
                imageUrls.push(this._getImageUrl(baseImageUrl, productId, `${page.lookupString}${String(i)}`));
            }
        });
        this.pages.map(page => {
            this._getImageUrl(baseImageUrl, productId, page.lookupString);
        });
        const imageQueries: string[] = [];
        const imageRequesters: (() => Promise<unknown>)[] = [];
        imageUrls.forEach(ref => {
            imageRequesters.push(() => sendRequest(ref, 'get'));
        });

        // Make image requests in parallel for improved speed
        const imageResponses = await promiseAllN(imageRequesters, 20);

        imageResponses.forEach((imageRequest: any) => {
            if (imageRequest?.status === 200) {
                imageQueries.push(imageRequest?.config?.url);
            }
        });

        // Return manual image search
        this.imageRefs = imageQueries;
    };
}

export default LookInside;
